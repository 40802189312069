<template>
  <div class="term-card">
    <apart>
      <div>
        <p class="is-size-4">{{ term.label }}</p>
        <p v-if="term.saving" class="has-text-danger">
          <strong>{{ term.saving }}</strong>
        </p>
      </div>
      <div class="has-text-right-tablet">
        <h3>
          <strong class="is-size-4">{{ monthlyTotal(term) }}</strong>
          <span class="is-size-5">/mo</span>
        </h3>
        <p>
          <span>
            Total due today
            <strong>{{ total(term) }}</strong>
          </span>
        </p>
      </div>
    </apart>
  </div>
</template>

<script>
export default {
  name: "TermCard",
  props: {
    term: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      default: () => {
        return null;
      }
    }
  },
  computed: {
    computedCurrency() {
      return this.currency || this.$store.getters["user/currency"]();
    }
  },
  methods: {
    total(term) {
      const termPrice = term.termPrice[this.computedCurrency] || 0;
      return this.$formatCurrency(termPrice, this.computedCurrency);
    },
    monthlyTotal(term) {
      const monthlyTermPrice =
        (term.termPrice[this.computedCurrency] || 0) / (term.termLength || 1);
      return this.$formatCurrency(monthlyTermPrice, this.computedCurrency);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.term-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0.75em;
  border: 2px dotted $grey-lighter;
  border-radius: 0.5em;
  cursor: pointer;
  transition-property: transform;
  transition-timing-function: ease-out;
  transition-duration: 0.12s;
  &:hover {
    transform: scale(1.025);
  }
  &.is-selected {
    border-color: $success;
    border-style: solid;
    box-shadow: 0 0 0.5em 0 rgba($success, 0.25);
  }
}
</style>
